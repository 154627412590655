"use strict";
import '/src/scss/style.scss';
import * as slide from "/src/js/slide";

window.addEventListener('DOMContentLoaded', () => {
	/*****   ユーザー環境取得   *****/
	/* iphone safari */
	const isIphone = navigator.userAgent.match(/iPhone/i) ? true : false;
	const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') == -1 &&
		navigator.userAgent.indexOf('FxiOS') == -1;
	if (isIphone && isSafari) {
		document.body.setAttribute('data-device', 'iphone');
		document.querySelector("html").setAttribute('data-device', 'iphone');
	}



	const header = document.getElementById('header');
	let window_width = window.innerWidth,
		window_height = window.innerHeight,
		breakpoint_sp = 768,
		header_height = header.offsetHeight;

	/**************************
		
		jsonデータ 取得

	***************************/
	const news_data_url = "https://fortee.jp/phperkaigi-2025/api/news",
		sponsor_data_url = "https://fortee.jp/phperkaigi-2025/api/sponsors",
		staff_data_url = "https://fortee.jp/phperkaigi-2025/api/staff?type=structured";

	/******************
		data取得関数
		@param {storng} [url] 取得するJSONデータのURL
		@return promise 
	*******************/
	async function getJsonData(url) { // (1)
		try {
			const res = await fetch(url);
			if (!res.ok) {
				throw new Error("fetchに失敗しました");
			}
			let json_data = await res.json();
			return json_data;
		} catch (error) {
			console.error("エラーです:", error);
			return false;
		}
	}

	/******************
		news
	*******************/
	getJsonData(news_data_url).then((json_data) => {
		const news = json_data["news"],
			news__wrapper = document.getElementById('js-news__wrapper'),
			news__switch = document.getElementById('js-news__acc_switch'),
			acc_base = 5;
		let html = "",
			count = 0;

		news.forEach((news) => {
			let published_date = news['published'],
				date = new Date(published_date).toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }), //  yyyy/mm/dd形式
				datetime_date = date.replaceAll('/', '-'),
				html_date = date.replaceAll('/', '.'),
				tit = news['title'] ? news['title'] : "",
				url = news['url'] ? news['url'] : "",
				inner_tag = url ? "a" : "span";

			count++;

			if (count === 1) {
				html +=
					`
                <ul class="news__list">
                `
			}
			else if (count === acc_base) {
				html +=
					`
                </ul>
                <ul class="news__list js-acc_block">   
                `
			}

			html +=
				`
			<li class="news__list_item">
				<${inner_tag} href="${url}" target="_blank" rel="noopener" class="news__list_item_inner" >
					<span class="news__list_date">
						<time datetime="${datetime_date}">${html_date}</time>
					</span>
					<p class="news__list_tit">
						${tit}
					</p>
				</${inner_tag}>
			</li>
			`
		});

		html +=
			`
        </ul>
        `
		if (count >= acc_base) {
			news__switch.removeAttribute("style");
		}
		html +=
			`
		</div>
		`

		news__wrapper.insertAdjacentHTML('beforeend', html);
	});


	/******************
		sponsors
	*******************/
	getJsonData(sponsor_data_url).then((json_data) => {
		const sponsor_plans = json_data["sponsor_plans"],
			sponsors__wrapper = document.getElementById("sponsors__wrapper"),
			modal = document.getElementById("js-modal_sponsors");

		let html = "",
			modal_html = "",
			modal_sponsor_id_array = [];

		if (sponsors__wrapper) {
			sponsor_plans.find((plan) => {
				const plan_name = plan['name'],
					plan_name_en = plan['name_en'],
					item_num = plan.sponsors.length;
				if (item_num) {
					html +=
						`
				<div class="sponsors__block" data-type="${plan_name_en}">
					<div class="sponsors__tit_block">
						<span class="sponsors__tit_block_mark">
						</span>
						<h3 class="sponsors__tit_block_heading">
							${plan_name}
						</h3>
						<p class="sponsors__tit_block_en">
							<span>
							${plan_name_en}
							</span>
						</p>
					</div>
					<ul id="js-sponsors_diamond" class="sponsors__list" style="--item-num:${item_num}">
				`;
					plan.sponsors.forEach((sponsor) => {
						const id = "modal-" + sponsor['id'] ? sponsor['id'] : "",
							name = sponsor['name'] ? sponsor['name'] : "",
							url = sponsor['url'] ? sponsor['url'] : "",
							img = sponsor['avatar'] ? sponsor['avatar'] : "",
							pr = sponsor['pr'] ? sponsor['pr'] : "",
							twitter_account_name = sponsor['twitter'] ? sponsor['twitter'] : "";
						let url_link = '',
							img_html = '',
							modal_img_html = '',
							link_list_x = '';

						/* url 分岐 */
						if (url) {
							url_link =
								`
								<a href="${url}" class="sponsors__box_link sponsors__box_link--website" target="_blank" rel="noopener">
									${url}
								</a>
							`
						}

						/* img 分岐 */
						if (img) {
							img_html =
								`
							<img src="${img}" alt="${name}">
							`

							modal_img_html =
								`
							<img src="${img}" class="modal__img" alt="${name}">
							`
						}
						else {
							img_html =
								`
							<img src="./assets/img/phperkun--gray.svg" class="none_img" alt="${name}">
							<p class="sponsors__list_item_img_box_name">${name}</p>
							`

							modal_img_html =
								`
							<img src="./assets/img/phperkun--gray.svg" class="modal__img none" alt="${name}">
							`
						}

						/* linkの有無によって */
						if (twitter_account_name) {
							link_list_x =
								`
								<a href="https://twitter.com/${twitter_account_name}" class="sponsors__box_link_x_btn link_btn link_btn--x" target="_blank" rel="noopener">
								@${twitter_account_name}
								</a>
							`
						}

						//sponsor html
						if (plan_name_en == 'Diamond Sponsors') {
							html +=
								`
							<li class="sponsors__list_item">
								<section class="sponsors__box">
									<div class="sponsors__box_img_block" data-id="${id}">
										${img_html}
									</div>
									<div
									<div class="sponsors__box_link_x">
										${link_list_x}
									</div>
									<h3 class="sponsors__box_tit">
										${name}
									</h3>
									${url_link}
									<p class="sponsors__box_text">
									${pr}
									</p>
								</section>
							</li>
							`
						} else {
							html +=
								`
							<li class="sponsors__list_item">
								<figure class="sponsors__list_item_inner js-modal_switch" data-id="${id}">
									<div class="sponsors__box sponsors__list_item_img_box">
										${img_html}
									</div>
								</figure>
							</li>
							`
						}


						//modal用
						if (!(modal_sponsor_id_array.includes(id))) {
							modal_sponsor_id_array.push(id);
							modal_html +=
								`
							<section id="${id}" class="modal__contents modal_sponsors__contents sponsors__block" data-type="${plan_name_en}">
								<div class="sponsors__box sponsors__box--modal">
									<div class="modal__contents_inner">
										<div class="sponsors__box_img_block">
											${modal_img_html}
										</div>
										<div class="sponsors__box_link_x">
											${link_list_x}
										</div>
										<h3 class="sponsors__box_tit">
											${name}
										</h3>
										${url_link}
										<p class="sponsors__box_text">
										${pr}
										</p>
										<span class="sponsors__box_mark"></span>
									</div>
									<div class="modal__cancel_btn js-modal_cancel"></div>
								</div>
							</section>
							`
						}
					});

					//タグ閉じ
					html +=
						`
					</ul>
					</div>
					`
				}
			});

			sponsors__wrapper.insertAdjacentHTML('beforeend', html);
			if (modal) {
				modal.insertAdjacentHTML('beforeend', modal_html);
			}
		}
	});


	/******************
		staff
	*******************/
	getJsonData(staff_data_url)
		.then((json_data) => {
			const staff_types = json_data["staff_types"],
				staff__wrapper = document.getElementById('js-staff__wrapper');

			let html = "",
				count = 0;

			if (staff__wrapper) {
				staff_types.forEach((type) => {
					count++;
					const staff = type['staff'],
						type_name = type['name'],
						type_name_en = type['name_en'],
						acc_block_class = staff.length > 6 ? "staff__acc_block" : "";

					if (staff.length) {
						html +=
							`
							<div class="staff__tit_block">
							<h3 class="staff__tit_block_tit">
								<span>
									${type_name}
								</span>
							</h3>
							</div>
							
							<div class="staff__acc_wrapper js-staff__acc_wrapper">
							<div class="${acc_block_class}">
							<div class="staff__block" data-type="${count}">
						`;

						staff.forEach((profile) => {
							let avatar_url = profile['avatar_url'],
								name = profile['name'],
								url = profile['url'],
								img_html = '';

							/* img 分岐 */
							if (avatar_url) {
								img_html =
									`
							<img src="${avatar_url}" alt="${name}">
							`
							}
							else {
								img_html =
									`
							<img src="./assets/img/phperkun--gray.svg" class="none_img" alt="${name}">
							`
							}

							html +=
								`
						<a href="${url}" class="staff_box" target="_blank" rel="noopener">
							<figure>
								<span class="staff_box__inner">
									${img_html}
								</span>
								<figcaption class="staff_box__caption">
									${name}
								</figcaption>
							</figure>
						</a>
						`
						});

						html +=
							`
							</div>
							</div>
							`
						html +=
							`
							</div>
							`
					}
				});

				staff__wrapper.insertAdjacentHTML('beforeend', html);
			}
		});




	/**************************
	
			hamburger menu
	
	***************************/
	let hamburger_menu_btn = document.getElementById('js-hamburger_btn'),
		header_link = header.querySelectorAll('a');
	if (hamburger_menu_btn) {
		hamburger_menu_btn.addEventListener('click', function () {
			header.classList.toggle('hamburger-active');
		}, false);

		header_link.forEach((target) => {
			target.addEventListener('click', function () {
				header.classList.remove('hamburger-active');
			});
		});
	}


	/**************************
	
		modal
	
	***************************/
	const modal = document.getElementById("js-modal");
	let positionY = -1 * window.scrollY;

	if (modal) {
		document.addEventListener('click', (e) => {
			let target = e.target;
			if (target.classList.contains('js-modal_switch')) {
				let target_id = target.dataset.id;
				if (target.dataset.id) {
					let target_modal = document.getElementById(target_id);

					if (target_modal) {
						positionY = window.scrollY;
						document.body.style.top = -1 * positionY + "px";
						modal.classList.add("modal-active");
						document.body.classList.add("modal-active");

						const modal__img = target_modal.querySelector('.modal__img'),
							img_src = modal__img.dataset.src;
						if (img_src) {
							modal__img.setAttribute('src', img_src);
						}
						target_modal.classList.add("current");
					}
				}
			}
		});
		modal.addEventListener('click', (e) => {
			let target = e.target;
			if (target.classList.contains('js-modal__inner') || target.classList.contains('js-modal_cancel') || target.tagName.toLowerCase() == 'a') {
				let current_modal = document.querySelectorAll('.modal__contents.current');
				current_modal.forEach((current_modal) => {
					current_modal.classList.remove("current");
				});
				modal.classList.remove("modal-active");
				document.body.classList.remove("modal-active");

			}
		});
	}



	/**************************
	
			スムーススクロール
		
	***************************/
	document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			let href = anchor.getAttribute('href'),
				adjust = -1*header_height - 25,
				target = document.getElementById(href.replace('#', ''));
				if(window_width < breakpoint_sp) {
					adjust = -7;
				}

			if (target) {
				let target_position = target.getBoundingClientRect().top + window.scrollY + adjust;

				window.scrollTo({
					top: target_position,
					behavior: 'smooth',
				});
			}
		});
	});


	/**************************
	
				アコーディオン機能 
			
	***************************/
	const acc_swith = document.querySelectorAll(".js-acc_switch");

	acc_swith.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			let target = e.target.closest(".js-acc_wrapper").querySelector(".js-acc_block");
			let style = getComputedStyle(target);
			if (!(style.overflow == "hidden")) {
				if (style.display == "none") {
					target.closest(".js-acc_wrapper").classList.add("active");
					target.classList.add("active");
					slide.slideDown(target, 620);
				}
				else {
					target.closest(".js-acc_wrapper").classList.remove("active");
					target.classList.remove("active");
					slide.slideUp(target, 620);
				}
			}
		});
	});

});
